@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}

.bg-home {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.bg-cards {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-prev,
.slick-next {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  color: #148dd8 !important;
}
.slick-prev{
  margin-right: 5px;
}
